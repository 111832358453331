<template>
  <div v-if="init" class="categories">
    <div v-if="editingMode != null" class="add-edit-category-groups">
      <div class="add-edit-category-groups-container">
        <span class="close material-icons" @click="clearEditing">close</span>
        <h2>add/edit category group</h2>

        <div class="icon-select-wrapper">
          <div class="selected-label">Icon</div>

          <div class="icon-select-container">
            <div class="selected" @click="iconSelectOpen = !iconSelectOpen">
              <span class="label" v-if="editingIcon === null">Select icon</span>
              <span class="label" v-else>
                <img
                  :src="
                    require('../assets/category-icons/' + editingIcon + '.png')
                  "
                />
                {{ editingIcon }}
              </span>
              <span class="material-icons">arrow_downward</span>
            </div>
            <div class="icon-select-options" v-if="iconSelectOpen">
              <div
                v-for="icon in categoryIcons"
                :key="icon"
                @click="selectIcon(icon)"
              >
                <img
                  :src="require('../assets/category-icons/' + icon + '.png')"
                />
                <span>{{ icon }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="search-select-wrapper">
          <div class="selected-label">
            Categories: <span>{{ selectedEditingCategoriesNames }}</span>
          </div>
          <div class="search-select">
            <input
              type="search"
              placeholder="Search categories"
              v-model="categorySearch"
            />
            <div class="result-container">
              <div
                class="single-result"
                v-for="(cat, key) in getCategoriesBySearch(categorySearch)"
                :key="editingCategoryId + key"
              >
                {{ cat }}
                <div class="actions">
                  <span
                    v-if="!editingCategories.includes(parseInt(key))"
                    class="add"
                    @click="
                      $store.commit(
                        'categories/addEditingCategory',
                        parseInt(key)
                      )
                    "
                    >Select</span
                  >
                  <span
                    v-else
                    class="remove"
                    @click="
                      $store.commit(
                        'categories/removeEditingCategory',
                        parseInt(key)
                      )
                    "
                    >Remove</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="search-select-wrapper">
          <div class="selected-label">
            Extra categories:
            <span>{{ selectedEditingExtraCategoriesNames }}</span>
          </div>
          <div class="search-select">
            <input
              type="search"
              placeholder="Search categories"
              v-model="extraCategorySearch"
            />
            <div class="result-container">
              <div
                class="single-result"
                v-for="(cat, key) in getExtraCategoriesBySearch(
                  extraCategorySearch
                )"
                :key="editingCategoryId + key"
              >
                {{ cat.name }}
                <div class="actions">
                  <span
                    v-if="!editingExtraCategories.includes(key)"
                    class="add"
                    @click="
                      $store.commit('categories/addEditingExtraCategory', key)
                    "
                    >Select</span
                  >
                  <span
                    v-else
                    class="remove"
                    @click="
                      $store.commit(
                        'categories/removeEditingExtraCategory',
                        key
                      )
                    "
                    >Remove</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="search-select-wrapper" v-if="appBrand !== 'fiskerikajen'">
          <div class="selected-label">Process</div>
          <input
            type="text"
            v-model="editingProcess"
            placeholder="Enter process, comma separated"
            class="process-input"
          />
        </div>

        <div class="checkbox-select-wrapper">
          <div class="selected-label">Locations</div>

          <div
            class="categories-check"
            @click="$store.commit('categories/toggleEditingLocation', 'home')"
          >
            <div class="check" :class="{ active: editingLocations.home }"></div>
            <label>Home</label>
          </div>

          <div
            class="categories-check"
            @click="
              $store.commit('categories/toggleEditingLocation', 'products')
            "
          >
            <div
              class="check"
              :class="{ active: editingLocations.products }"
            ></div>
            <label>Products</label>
          </div>
        </div>

        <div class="search-select-wrapper">
          <div class="selected-label">
            Segments: <span>{{ selectedEditingSegmentsNames }}</span>
          </div>
          <div class="search-select">
            <input
              type="search"
              placeholder="Search segments"
              v-model="segmentSearch"
            />
            <div class="result-container">
              <div
                class="single-result"
                v-for="(segment, key) in getSegmentsBySearch(segmentSearch)"
                :key="editingCategoryId + key"
              >
                {{ segment }}
                <div class="actions">
                  <span
                    v-if="!editingSegments.includes(parseInt(key))"
                    class="add"
                    @click="
                      $store.commit(
                        'categories/addEditingSegment',
                        parseInt(key)
                      )
                    "
                    >Select</span
                  >
                  <span
                    v-else
                    class="remove"
                    @click="
                      $store.commit(
                        'categories/removeEditingSegment',
                        parseInt(key)
                      )
                    "
                    >Remove</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="name-translations-wrapper">
          <div class="selected-label">Name</div>
          <div
            class="single-translation"
            v-for="lang in enabledLanguages"
            :key="lang.code"
          >
            <img :src="require('../assets/flags/' + lang.code + '.png')" />
            <input
              type="text"
              :placeholder="lang.name"
              :value="editingTranslations[lang.code]"
              @blur="
                $store.commit('categories/updateCategoryName', {
                  lang: lang.code,
                  value: $event.target.value,
                })
              "
            />
          </div>
        </div>

        <div class="footer-actions">
          <button
            v-if="editingMode == 'edit'"
            class="delete"
            @click="deleteGroupCategory"
          >
            Delete
          </button>
          <button class="save" @click="save">Save</button>
        </div>
      </div>
    </div>

    <h1>Categories</h1>

    <button @click="$store.dispatch('categories/initCreateEditing')">
      Add category
    </button>
    <br />
    <br />

    <div class="admin-table product-category-groups-table">
      <div class="table-header">
        <span class="label edit">Edit</span>
        <span class="label icon">Icon</span>
        <span class="label categories">Selected categories</span>
        <span class="label extra-categories">Extra categories</span>
        <span class="label locations">Locations</span>
        <span class="label segments">Segments</span>

        <span
          class="label lang"
          v-for="lang in enabledLanguages"
          :key="'label-' + lang.code"
          ><img :src="require('../assets/flags/' + lang.code + '.png')"
        /></span>
      </div>

      <div
        v-for="category in sortedCategoryGroups"
        :key="'category-id-' + category.id"
      >
        <span class="edit">
          <span
            class="material-icons edit-icon"
            @click="$store.dispatch('categories/initEditEditing', category.id)"
            >edit</span
          >
        </span>

        <span class="icon">
          <img
            v-if="category.icon !== null"
            style="width: 40px"
            :src="require('../assets/category-icons/' + category.icon + '.png')"
          />
        </span>

        <span class="categories">
          <span v-for="cat in category.categories" :key="category.id + cat">
            {{ getCategoryNameById(cat) }}
          </span>
        </span>

        <span class="categories">
          <span
            v-for="cat in category.extraCategories"
            :key="category.id + cat"
          >
            {{ getExtraCategoryNameById(cat) }}
          </span>
        </span>

        <span class="locations">
          <span>Filter</span>
          <span v-if="category.locations.home">Home</span>
          <span v-if="category.locations.products">Products</span>
        </span>

        <span class="segments">
          <span
            v-for="segment in category.segments"
            :key="category.id + segment"
          >
            {{ getPriceGroup2ById(segment) }}
          </span>
        </span>

        <span
          class="lang"
          v-for="lang in enabledLanguages"
          :key="'option-' + lang.code"
          >{{ categoryGroupsTranslated[lang.code][category.id] }}</span
        >

        <!-- <div class="controlls" v-if="userRole.slug === 'superAdmin'">
          <div class="edit" @click="editCategory(id)">
            <span class="material-icons edit-icon icon">edit</span>
          </div>
          <div class="delete" @click="deleteCategory(id)">
            <span class="material-icons delete-icon icon"> delete </span>
          </div>
        </div> -->
      </div>
    </div>

    <div class="categories-sort-wrapper">
      <h3>Sort home/products order</h3>
      <draggable
        class="categories-draggable"
        v-model="sortableCategoryGroups"
        @start="onDragStart"
        @end="onDragEnd"
      >
        <div
          v-for="group in sortableCategoryGroupsWithData"
          :key="'display-' + group.id"
        >
          <span class="icon">
            <img
              v-if="group.icon !== null"
              :src="require('../assets/category-icons/' + group.icon + '.png')"
            />
          </span>
          <span class="name">{{
            getCategoryGroupTranslationById(group.id, "en")
          }}</span>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";
import { db } from "../main";

export default {
  data() {
    return {
      categorySearch: "",
      extraCategorySearch: "",
      segmentSearch: "",
      categoryIcons: [
        "can",
        "caviar",
        "clam",
        "crab",
        "cutlet",
        "delicacies-2",
        "delicacies",
        "fish-2",
        "fish-filet-2",
        "fish-filet",
        "fish-frozen",
        "fish",
        "flatfish",
        "fruits",
        "herring",
        "lobster",
        "maki",
        "material",
        "meat",
        "mochi",
        "octopus-2",
        "octopus",
        "other",
        "oyster",
        "salmon",
        "sardines",
        "sea-urchin",
        "shrimp-2",
        "shrimp-frozen",
        "shrimp",
        "smoked-fish",
        "smoked",
        "sushi",
        "tropical",
        "tuna",
        "beverages",
        "gari",
        "seaweed",
        "soya",
        "miscellaneous",
        "noodles",
        "snowflake",
        "fish-3",
        "prawn",
      ],
      iconSelectOpen: false,
    };
  },
  mounted() {},
  created() {
    console.log(this.categories);
  },
  components: { draggable },
  computed: {
    editingProcess: {
      get() {
        return this.$store.state.categories.editingProcess;
      },
      set(value) {
        this.$store.commit("categories/setEditingProcess", value);
      },
    },
    ...mapState({
      productList: (state) => state.products.productList,
      init: (state) => state.settings.init,
      categories: (state) => state.settings.productSettings.categories,
      categoriesTranslated: (state) =>
        state.settings.productSettings.categoriesTranslated,
      categoryGroups: (state) => state.settings.productSettings.categoryGroups,
      categoryGroupsTranslated: (state) =>
        state.settings.productSettings.categoryGroupsTranslated,
      editingMode: (state) => state.categories.editingMode,
      editingIcon: (state) => state.categories.editingIcon,
      editingCategories: (state) => state.categories.editingCategories,
      editingExtraCategories: (state) =>
        state.categories.editingExtraCategories,
      editingLocations: (state) => state.categories.editingLocations,
      editingSegments: (state) => state.categories.editingSegments,
      editingTranslations: (state) => state.categories.editingTranslations,
      editingCategoryId: (state) => state.categories.editingCategoryId,
      appBrand: (state) => state.settings.appSettings.app,
    }),
    ...mapGetters("products", ["getProductsBySearch", "productCount"]),
    ...mapGetters("languages", ["enabledLanguages"]),
    ...mapGetters("settings", [
      "getCategoryNameById",
      "getExtraCategoryNameById",
      "getPriceGroup2ById",
      "getCategoriesBySearch",
      "getExtraCategoriesBySearch",
      "getSegmentsBySearch",
      "getCategoryGroupTranslationById",
      "sortedCategoryGroups",
    ]),
    ...mapGetters("categories", [
      "selectedEditingCategoriesNames",
      "selectedEditingExtraCategoriesNames",
      "selectedEditingSegmentsNames",
      "sortableCategoryGroupsWithData",
    ]),
    sortableCategoryGroups: {
      get() {
        return this.$store.getters["categories/sortableCategoryGroups"];
      },
      set(value) {
        console.log(value);
        let i = 0;
        let data = {};
        value.forEach((id) => {
          data[id] = i;
          i++;
        });

        db.collection("app").doc("appHelpers").update({
          categoryGroupsOrder: data,
        });
      },
    },
  },
  methods: {
    clearEditing() {
      this.segmentSearch = "";
      this.categorySearch = "";
      this.extraCategorySearch = "";
      this.iconSelectOpen = false;
      this.$store.dispatch("categories/clearEditing");
    },
    addCategory() {
      console.log("add");
      this.$store.commit("categories/addEditingCategory");
    },
    removeCategory() {
      console.log("remove");
    },
    async deleteGroupCategory() {
      let confirm = window.confirm(
        "Permanently delete price group?\nThis can not be undone."
      );
      if (confirm) {
        await this.$store.dispatch("categories/deleteCategoryGroup");
        this.$store.dispatch("categories/clearEditing");
        console.log("done");
      }
    },
    selectIcon(icon) {
      this.iconSelectOpen = false;
      this.$store.commit("categories/setEditingIcon", icon);
    },
    async save() {
      console.log("save");
      await this.$store.dispatch("categories/saveCategoryGroup");
      this.$store.dispatch("categories/clearEditing");
      this.segmentSearch = "";
      this.categorySearch = "";
      this.extraCategorySearch = "";
    },
    onDragStart: function (evt) {
      console.log(evt);
      // this.$store.commit("notifications/setSelectedBlockIndex", evt.oldIndex);
    },
    onDragEnd: function (evt) {
      console.log({
        item: evt.item,
        from: evt.from,
        to: evt.to,
        oldIndex: evt.oldIndex,
        newIndex: evt.newIndex,
      });

      // this.$store.commit("notifications/setSelectedBlockIndex", evt.newIndex);
    },
  },
};
</script>
